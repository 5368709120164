import React, { useState } from 'react';
import './MobileHeader.scss';
import UOSLogo from '../../assets/UOS-logo.png';
import { Squash as Hamburger } from 'hamburger-react';
import Sidebar from '../Sidebar/Sidebar';
import { Link } from 'react-router-dom';
import MobileHeaderSmall from '../MobileHeaderSmall/MobileHeaderSmall';
import Banner from '../../assets/banner.jpg';

const MobileHeader = () => {
  const [isOpen, setOpen] = useState(false);

  const handleSidebarOpen = () => {
    return setOpen(false);
  };

  return (
    <div className="mobile-header">
      {isOpen && <Sidebar handleSidebarOpen={handleSidebarOpen} isOpen={isOpen} />}
      <div className="inner-container">
        <MobileHeaderSmall main={true} />
        {/* <div className="navigation">
          <Link to={'/'}>
            <img className="image" src={''} />
          </Link>
          <div className="burger-menu">
            {!isOpen && <Hamburger toggled={false} toggle={setOpen} color="white" />}
          </div>
        </div> */}
        <div className="context">
          <div className="image-wrapper">
            <img className="img-first" src={Banner} alt="" />
            <img className="img-second" src={Banner} alt="" />
          </div>
          {/* <p className="title animatedFadeInUp fadeInUp">8. SRPSKI DANI OSIGURANJA</p> */}
          {/* <p className="sub-title-first animatedFadeInUp fadeInUp">
            Ako ne mi, ko? Ako ne sad, kad?
          </p> */}
          {/* <p className="sub-title-second-first animatedFadeInUp fadeInUp">Džon F. Kenedi</p> */}
          {/* <p className="sub-title animatedFadeInUp fadeInUp">Hotel Zlatibor 28.11.-01.12.2023.</p> */}
        </div>
      </div>
    </div>
  );
};

export default MobileHeader;
