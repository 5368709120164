import { useState, useEffect, ChangeEvent, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Home from '../../assets/home.png';
import PersonalDataIcon from '../../assets/personal-data-icon.png';
import './LegalEntity.scss';
import { PlusOutlined } from '@ant-design/icons';
import { Form, Button, Input, Select, Checkbox, Space, AutoComplete, Modal } from 'antd';
import NewParticipant from '../NewParticipant/NewParticipant';
import AddNewAccommodation from '../AddNewAccommodation/AddNewAccommodation';
import {
  createCompanyRegistration,
  getAllCompanyInformation
} from '../../services/RegistrationService';
import { CreatedType, RegistrationStatus } from '../../models/Registration';
import { getAllEvents } from '../../services/EventService';
import { EventDto, EventListDto } from '../../models/Event';
import { AdditionalPersonDto, AccommodationDto } from '../../models/Accommodation';
import { validateRecaptcha } from '../../services/RecaptchaService';
import useGoogleReCaptchaV3 from '../../hooks/UseGoogleRecaptcha3';
import { Config } from '../../config/config';
import { Toast } from 'primereact/toast';
import { addThousandSeparator } from '../../core/Utility';

const LegalEntity = (props: any) => {
  const [showFirst, setShowFirst] = useState<boolean>(true);
  const [showSecond, setShowSecond] = useState<boolean>(true);
  const [form] = Form.useForm();
  const isAccommodationRequired = Form.useWatch('isAccommodationRequired', form);
  const [oneEvent, setOneEvent] = useState<EventDto>();
  const [allEvents, setAllEvents] = useState<EventListDto[]>([]);
  const [companyOptions, setCompanyOptions] = useState<{ label: string; value: string }[]>([]);
  const [allCompanies, setAllCompanies] = useState<any[]>([]);
  const [totalPrice, setTotalPrice] = useState<any>(0);
  const [getNewPriceData, setGetNewPriceData] = useState<boolean>(false);
  const registrationFee = oneEvent?.registrationFee ?? 0;
  const [status, setStatus] = useState<any>(undefined);
  const [textMessage, setTextMessage] = useState<any>(undefined);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const toast = useRef<any>(null);
  const Country = [
    { label: 'Srbija', value: 'Srbija' },
    { label: 'Inostranstvo', value: 'Inostranstvo' }
  ];

  const { executeReCaptcha } = useGoogleReCaptchaV3({ siteKey: Config.reCaptchaKey });

  useEffect(() => {
    if (!status) return;
    if (status !== 409) {
      setStatus(undefined);
      setShowInfoModal(true);
      return form.resetFields();
    } else {
      toast.current.show({
        severity: 'error',
        detail: `Već postoji uneti broj telefona ${textMessage}.`,
        life: 3000
      });
      setStatus(undefined);
    }
  }, [status]);

  useEffect(() => {
    const values = form.getFieldValue('accommodations');
    if (values?.length === 0) {
      handleTotalPrice(0);
    }
  }, [getNewPriceData]);

  useEffect(() => {
    if (!isAccommodationRequired) {
      let total = 0;
      const fieldsFormData = form.getFieldValue('additionalPerson');
      fieldsFormData?.map((el: any) => {
        if (el?.type === 'Učesnik') {
          total = total + registrationFee;
        }
      });
      setGetNewPriceData(false);
      return handleTotalPrice(total);
    } else {
      const fieldsFormData = form.getFieldValue('accommodations');
      if (!fieldsFormData || fieldsFormData.length === 0) {
        handleTotalPrice(0);
      }
    }
  }, [isAccommodationRequired, getNewPriceData]);

  useEffect(() => {
    setOneEvent(props.data);
  }, [props.data]);

  useEffect(() => {
    getAllEvents().then((eventResponse) => {
      const newEventOptions: EventListDto[] = [];
      eventResponse.data.result?.map((eventItem: EventDto) => {
        newEventOptions.push({
          label: eventItem.name,
          value: eventItem.name,
          id: eventItem.id
        });
      });
      setAllEvents(newEventOptions);
    });
    getAllCompanyInformation().then((companyResponse) => {
      const allCompany = companyResponse.data.result;
      const allNewCompanyOptions: { label: string; value: string }[] = [];
      const allNewCompanies: any[] = [];
      allCompany.map((item: any) => {
        allNewCompanies.push(item);
        allNewCompanyOptions.push({
          label: item.companyName,
          value: item.companyName
        });
      });
      setAllCompanies(allNewCompanies);
      setCompanyOptions(allNewCompanyOptions);
    });
  }, []);

  const handleGetPriceData = (get: boolean) => {
    setGetNewPriceData(get);
  };

  const handleCheckboxGroup = (e: ChangeEvent<HTMLInputElement>, name: string) => {
    if (name === 'noAccommodation') {
      form.setFieldValue('isAccommodationRequired', false);
      // form.setFieldValue('accommodations', []);
    } else {
      form.setFieldValue('noAccommodation', false);
      // form.setFieldValue('participants', []);
    }
  };

  const handleTotalPrice = (price: any) => {
    setTotalPrice(price);
  };

  const handleShowFirstAccordion = () => {
    return setShowFirst(!showFirst);
  };

  const handleShowSecondAccordion = () => {
    return setShowSecond(!showSecond);
  };

  const handleConfirmSaveForm = async () => {
    // const token = await executeReCaptcha('LoadReCaptchaOnClick');
    // const validationResponse = await validateRecaptcha(token);
    // if (!(validationResponse.data.result.success && validationResponse.data.result.score > 0.5)) {
    //   alert('Preveliki broj poziva');
    //   return;
    // }

    const fieldValuesAccommodations = form.getFieldValue('accommodations');
    const fieldValuesAdditionalPerson = form.getFieldValue('additionalPerson');

    let accommodationAdditionalPersonLength = 0;

    if (isAccommodationRequired) {
      fieldValuesAccommodations?.map((el: any) => {
        if (el.additionalPerson.length > 0) {
          return (accommodationAdditionalPersonLength =
            accommodationAdditionalPersonLength + el.additionalPerson.length);
        }
      });
    } else {
      accommodationAdditionalPersonLength = fieldValuesAdditionalPerson?.length ?? 0;
    }

    if (accommodationAdditionalPersonLength !== 0) {
      return handleSaveForm();
    } else {
      toast.current.show({
        severity: 'error',
        detail: 'Nije moguće kreirati prijavu bez učesnika!',
        life: 3000
      });
    }
  };

  const handleSaveForm = () => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        const data = form.getFieldsValue();

        data.createdType = CreatedType.Site;

        const singleEvent = allEvents.filter((el: EventListDto) => {
          if (el.id === props.idEvent) {
            return { id: el.id, name: el.value };
          }
        });

        data.event = { id: singleEvent[0].id, name: singleEvent[0].value };

        delete data.noAccommodation;

        if (data.isAccommodationRequired !== true) {
          data?.additionalPerson?.map((el: AdditionalPersonDto, i: number) => {
            // eslint-disable-next-line prefer-const
            let copyOfNewEl = {
              ...el,
              status: RegistrationStatus.Pending,
              lastStatusChange: new Date(),
              registrationFee: true
            };
            data.additionalPerson[i] = { ...copyOfNewEl };
          });
        } else {
          data?.accommodations?.map((el1: AccommodationDto, i: number) => {
            el1.additionalPerson?.map((el: AdditionalPersonDto, j: number) => {
              // eslint-disable-next-line prefer-const
              let copyOfNewEl = {
                ...el,
                status: RegistrationStatus.Pending,
                lastStatusChange: new Date(),
                isAccommodationRequired: true,
                roomId: el1.roomId,
                eventXRoomType: { id: el1.roomType },
                accommodation: { id: el1.accommodationId },
                roomTypeId: el1.roomType,
                numberOfDays: el1.numberOfDays,
                priceOfAccommodation:
                  Number(el1.priceOfAccommodation) / Number(el1.numberOfPersons),
                parkingPlace: el1.parkingPlaces,
                createdType: CreatedType.Site
              };
              data.accommodations[i].additionalPerson[j] = { ...copyOfNewEl };
            });
          });
        }

        createCompanyRegistration({
          ...data,
          date: new Date()
        })
          .then((response) => {
            setStatus(response.status);
            setTextMessage('');
          })
          .catch((err: any) => {
            let text = err.response.data.message.match(/\d/g);
            text = text.join('');
            setTextMessage(text);
            setStatus(err.response.status);
          });
      },
      () => {
        console.log('');
      }
    );
  };

  const onCompanyNameSelect = (value: any) => {
    const selectedCompany = allCompanies.find((item) => item.companyName == value);
    form.setFieldValue('isInsuranceCompany', selectedCompany.isInsuranceCompany);
    form.setFieldValue('companyAddress', selectedCompany.companyAddress);
    form.setFieldValue('companyId', selectedCompany.companyId);
    form.setFieldValue('vatNumber', selectedCompany.vatNumber);
    form.setFieldValue('companyEmail', selectedCompany.companyEmail);
    form.setFieldValue('companyPhone', selectedCompany.companyPhone.replace(/\s/g, ''));
    form.setFieldValue('companyStreet', selectedCompany.companyStreet);
    form.setFieldValue('companyCity', selectedCompany.companyCity);
    form.setFieldValue('companyCountry', selectedCompany.companyCountry);
    form.setFieldValue('companyStreetNumber', selectedCompany.companyStreetNumber);
  };

  return (
    <div className="legal-entity">
      <Toast ref={toast} position={'top-right'} />
      <Form form={form} name="new-event-form" layout="vertical" autoComplete="off">
        <div className="accordion">
          <div
            onClick={() => {
              handleShowFirstAccordion();
            }}
            className={'accordion-item'}>
            <div className="header-box">
              <img src={Home} />
              <p className="accordion-header" style={{ marginLeft: '10px' }}>
                Poslovni podaci
              </p>
            </div>
            <div style={{ marginRight: '10px' }} className="button-open">
              {showFirst ? (
                <FontAwesomeIcon icon={faChevronUp} color={'white'} />
              ) : (
                <FontAwesomeIcon icon={faChevronDown} color={'white'} />
              )}
            </div>
          </div>
        </div>

        <div className={showFirst ? 'accordion-body' : 'close'}>
          <div className="accordion-body-inner">
            <Form.Item
              name="companyName"
              label="Naziv pravnog lica:"
              rules={[
                {
                  required: true,
                  message: 'Popuniti obavezno polje!'
                }
              ]}>
              <AutoComplete
                allowClear={true}
                options={companyOptions}
                className="input-label"
                onSelect={(value) => onCompanyNameSelect(value)}
                placeholder="Unesite naziv pravnog lica"
                filterOption={(inputValue, option) =>
                  option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </Form.Item>
            <Form.Item className="form-box" name={'companyCountry'} label="Zemlja:">
              <Select placeholder="Izaberite Zemlju" options={Country} />
            </Form.Item>
            <Form.Item className="form-box" name={'companyCity'} label="Grad:">
              <Input className="input-label" placeholder="Unesite grad" />
            </Form.Item>
            <Form.Item className="form-box" name={'companyStreet'} label="Ulica:">
              <Input className="input-label" placeholder="Unesite ulicu" />
            </Form.Item>
            <Form.Item className="form-box" name={'companyStreetNumber'} label="Broj:">
              <Input className="input-label" placeholder="Unesite broj" />
            </Form.Item>
            <Form.Item
              name="vatNumber"
              label="PIB:"
              rules={[
                {
                  required: true,
                  message: 'Popuniti obavezno polje!'
                }
              ]}>
              <Input type="number" className="input-label" placeholder="Unesite PIB" />
            </Form.Item>
            <Form.Item
              name="companyId"
              label="Matični broj:"
              rules={[
                {
                  required: true,
                  message: 'Popuniti obavezno polje!'
                }
              ]}>
              <Input type="number" className="input-label" placeholder="Unesite matični broj" />
            </Form.Item>
            <Form.Item
              name="companyEmail"
              label="E-mail za dostavljanje predračuna:"
              rules={[
                {
                  required: true,
                  message: 'Popuniti obavezno polje!'
                },
                {
                  validateTrigger: 'submit',
                  type: 'email',
                  message: 'Unesite ispravan format e-maila!'
                }
              ]}>
              <Input className="input-label" placeholder="Unesite email adresu" />
            </Form.Item>
            <Form.Item name="companyPhone" label="Mobilni telefon:">
              <Input type="number" className="input-label" placeholder="Unesite kontakt telefon" />
            </Form.Item>
            <div className="checkbox-group">
              <Form.Item name="isAccommodationRequired" valuePropName="checked">
                <Checkbox
                  onChange={(e: any) => handleCheckboxGroup(e, 'isAccommodationRequired')}
                  className="checkbox">
                  <p className="checkbox-text">Potreban smeštaj</p>
                </Checkbox>
              </Form.Item>
              <Form.Item name="noAccommodation" valuePropName="checked">
                <Checkbox
                  onChange={(e: any) => handleCheckboxGroup(e, 'noAccommodation')}
                  className="checkbox">
                  <p className="checkbox-text">Bez smeštaja</p>
                </Checkbox>
              </Form.Item>
            </div>
          </div>
        </div>

        <div className="accordion">
          <div
            onClick={() => {
              handleShowSecondAccordion();
            }}
            className={'accordion-item'}>
            <div className="header-box">
              <img src={PersonalDataIcon} />
              <p className="accordion-header" style={{ marginLeft: '10px' }}>
                {isAccommodationRequired ? 'Podaci o učesnicima i smeštaju' : 'Podaci o učesnicima'}
              </p>
            </div>
            <div style={{ marginRight: '10px' }} className="button-open">
              {showSecond ? (
                <FontAwesomeIcon icon={faChevronUp} color={'white'} />
              ) : (
                <FontAwesomeIcon icon={faChevronDown} color={'white'} />
              )}
            </div>
          </div>
        </div>

        <div className={showSecond ? 'accordion-body' : 'close'}>
          {!isAccommodationRequired ? (
            <Form.List name="additionalPerson">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space key={key} className="list-of-data" align="baseline">
                      <NewParticipant
                        form={form}
                        handleGetPriceData={handleGetPriceData}
                        name={name}
                        restField={{ ...restField }}
                        remove={remove}
                        individual={false}
                      />
                    </Space>
                  ))}
                  <Form.Item className="add-new-btn">
                    <Button icon={<PlusOutlined />} type="primary" onClick={() => add()}>
                      Dodajte učesnika
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          ) : (
            <Form.List name="accommodations">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space key={key} className="list-of-data" align="baseline">
                      <AddNewAccommodation
                        form={form}
                        needAccommodation={isAccommodationRequired}
                        name={name}
                        restField={{ ...restField }}
                        remove={remove}
                        idEvent={props.idEvent}
                        oneEvent={oneEvent}
                        individual={false}
                        handleTotalPrice={handleTotalPrice}
                        handleGetPriceData={handleGetPriceData}
                        registrationFee={registrationFee}
                      />
                    </Space>
                  ))}
                  <Form.Item className="add-new-btn">
                    <Button icon={<PlusOutlined />} type="primary" onClick={() => add()}>
                      Dodaj sobu
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          )}
        </div>

        <div className={'additional-fields'}>
          <div className="box">
            <div className="price-box">
              <p className="price-text">Cena sa PDV u RSD:</p>
              <span className="price-input">{addThousandSeparator(totalPrice)}</span>
            </div>
            {/* <Button className="btn-save-application" type="primary" danger>
              SAČUVAJ PRIJAVU
            </Button> */}
            <Button
              className="btn-save-form"
              type="primary"
              danger
              htmlType="submit"
              onClick={handleConfirmSaveForm}>
              POTVRDI PRIJAVU
            </Button>
          </div>
        </div>
        <p className="text-blue">
          **** Organizator zadržava pravo da ne prihvati prijavu bez obaveze daljeg obrazloženja.
        </p>
      </Form>
      <Modal
        open={showInfoModal}
        title={`Uspešno kreirana prijava`}
        onCancel={() => setShowInfoModal(false)}
        onOk={() => setShowInfoModal(false)}
        footer={null}>
        <p>Poštovana/i</p>
        <p className="notification-content">{`
              Vaša prijava je uspešno evidentirana u našem sistemu. Ukoliko Vaša prijava bude odobrena bićete kontaktirani.
              `}</p>
        <p>Hvala Vam na interesovanju, Vaš UOS</p>
      </Modal>
    </div>
  );
};

export default LegalEntity;
