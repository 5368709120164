import { useState, useEffect } from 'react';
import './TimeContainer.scss';

const TimeContainer = () => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const deadline = 'November, 26, 2024 18:00:00';

  const getTime = (date: any) => {
    const time = Date.parse(date) - Date.now();

    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  useEffect(() => {
    const interval = setInterval(() => getTime(deadline), 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="time-container">
      <p className="title">HOTEL ZLATIBOR 26.11.-29.11.2024.</p>
      <p className="title-second">VREME DO POČETKA DOGAĐAJA</p>
      <div className="time-counter">
        <div className="row">
          <p className="time">{days}</p>
          <p className="label">dana</p>
        </div>
        <div className="row">
          <p className="time">{hours}</p>
          <p className="label">sati</p>
        </div>
        <div className="row">
          <p className="time">{minutes}</p>
          <p className="label">minuta</p>
        </div>
        <div className="row">
          <p className="time">{seconds}</p>
          <p className="label">sekundi</p>
        </div>
      </div>
    </div>
  );
};

export default TimeContainer;
