/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect, useRef } from 'react';
import './WhyJoinComponent.scss';

const WhyJoinComponent = (props: any) => {
  const [participants, setParticipants] = useState({ num: 0 });
  const [lecturer, setLecturer] = useState({ num: 0 });
  const [panels, setPanels] = useState({ num: 0 });
  const [theme, setTheme] = useState({ num: 0 });

  const counterParticipants = useRef(0);
  const counterLecturer = useRef(0);
  const counterPanels = useRef(0);
  const counterTheme = useRef(0);

  useEffect(() => {
    if (!props.start) return;
    if (counterParticipants.current < 1400) {
      counterParticipants.current += 1;
      const timer = setTimeout(() => setParticipants({ num: participants.num + 1 }), 1);
      return () => clearTimeout(timer);
    }
  }, [props.start, participants]);

  useEffect(() => {
    if (!props.start) return;

    if (counterLecturer.current < 180) {
      counterLecturer.current += 1;
      const lecturerTimer = setTimeout(() => setLecturer({ num: lecturer.num + 1 }), 32);
      return () => clearTimeout(lecturerTimer);
    }
  }, [props.start, lecturer]);

  useEffect(() => {
    if (!props.start) return;

    if (counterPanels.current < 70) {
      counterPanels.current += 1;
      const lecturerTimer = setTimeout(() => setPanels({ num: panels.num + 1 }), 105);
      return () => clearTimeout(lecturerTimer);
    }
  }, [props.start, panels]);

  useEffect(() => {
    if (!props.start) return;

    if (counterTheme.current < 90) {
      counterTheme.current += 1;
      const lecturerTimer = setTimeout(() => setTheme({ num: theme.num + 1 }), 75);
      return () => clearTimeout(lecturerTimer);
    }
  }, [props.start, theme]);

  return (
    <div className="main-container">
      <p className="title">ZAŠTO NAM SE PRIDRUŽITI?</p>
      <p className="text">
        Udruženje osiguravača Srbije od 2017. godine organizuje konferenciju "Srpski dani
        osiguranja" koja okuplja vodeće ljude struke iz Srbije, regiona i inostranstva, predstavnike
        Vlade Republike Srbije i Narodne banke Srbije, kao regulatornog tela. Odabirom tema i
        predavača, Konferencija daje odgovore na najvažnija pitanja industrije osiguranja i
        finansijskog sektora. Time doprinosimo daljem razvoju tržišta osiguranja, bržem i lakšem
        prilagođavanju standardima razvijenih tržišta i novim potrebama korisnika usluga osiguranja.
      </p>
      <div className="data-box">
        <div className="single-box">
          <p className="number">{participants.num}+</p>
          <p className="single-box-text">učesnika</p>
        </div>
        <div className="single-box">
          <p className="number">{lecturer.num}+</p>
          <p className="single-box-text">predavača</p>
        </div>
        <div className="single-box">
          <p className="number">{panels.num}+</p>
          <p className="single-box-text">panela</p>
        </div>
        <div className="single-box">
          <p className="number">{theme.num}+</p>
          <p className="single-box-text">tema</p>
        </div>
      </div>
    </div>
  );
};

export default WhyJoinComponent;
