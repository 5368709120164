/* eslint-disable react/no-unescaped-entities */
import './MainHeader.scss';
import Navigation from '../Navigation/Navigation';
import UOSLogo from '../../assets/UOS-logo-big.png';
import { Link } from 'react-router-dom';
import Header from '../Header/Header';
import Banner from '../../assets/banner.jpg';

const MainHeader = () => {
  return (
    <div className="main-header">
      <div className="inner-container">
        <Header main={true} />
        {/* <div className="navigation">
          <Navigation />
        </div> */}
        <div className="context">
          <div className="image-wrapper">
            <img className="img-first" src={Banner} alt="" />
            <img className="img-second" src={Banner} alt="" />
          </div>
          {/* <img className="image animatedFadeInUp fadeInUp" src={UOSLogo} /> */}
          {/* <p className="title  animatedFadeInUp fadeInUp">8. SRPSKI DANI OSIGURANJA</p> */}
          {/* <p className="subtitle-first animatedFadeInUp fadeInUp">
            "Ako ne mi, ko? Ako ne sad, kad?"
          </p> */}
          {/* <p className="subtitle-second animatedFadeInUp fadeInUp">Džon F. Kenedi</p> */}
          {/* <p className="subtitle animatedFadeInUp fadeInUp">HOTEL ZLATIBOR 28.11.-1.12.2023.</p> */}
          {/* <div className="button-container animatedFadeInUp fadeInUp">
            <Link to={'/sign-up'} className="btn blue">
              PRIJAVA
            </Link>
            <button className="btn white">MOBILNA APLIKACIJA</button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default MainHeader;
