import React, { useState } from 'react';
import './Venue.scss';
import { Link } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import video from '../../assets/video/video.mp4';

const Venue = () => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <div className="venue">
      <div className="left-container">
        <p className="title">Mesto održavanja</p>
        <a href="https://www.hotelzlatibor-resort.com/" className="sub-title">
          HOTEL ZLATIBOR
        </a>
        <p className="text">
          Osmi Srpski dani osiguranja biće održani od 26.11. do 29.11. na Zlatiboru u hotelu
          Zlatibor. <span>{'"Srpski dani osiguranja"'}</span> su tradicionalna konferencija koja
          okuplja domaće i strane stručnjake iz oblasti osiguranja, čiji je organizator Udruženje
          osiguravača Srbije. Jedna je od najvećih u regionu koja je u potpunosti posvećena temama
          iz oblasti osiguranja.
        </p>

        {/* <div className="btn-container">
          <Link
            to={'/accommodation'}
            style={isHovering ? { color: '#0D4077' } : {}}
            className="btn">
            POGLEDAJTE VIŠE
          </Link>
        </div> */}
      </div>
      <div className="right-container">
        <div className="image-wrapper">
          {/* <Link to={'/accommodation'}> */}
          <video
            className="video"
            autoPlay={true}
            muted={true}
            playsInline={true}
            loop={true}
            height="500px"
            src={video}
          />
          {/* </Link> */}
        </div>
        {/* <div className="btn-container-mobile">
          <Link
            to={'/accommodation'}
            style={isHovering ? { color: '#0D4077' } : {}}
            className="btn-mobile">
            POGLEDAJTE VIŠE
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default Venue;
