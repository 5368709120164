import './Footer.scss';

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="footer-container-left">
          <div className="inner-container">
            <p className="title">Kontaktirajte nas:</p>
            <input className="input" placeholder="Ime i prezime:" />
            <input className="input" placeholder="Email:" />
            <input className="input message" placeholder="Poruka:" />
            {/* <p className="text">
              *Lorem ipsum dolor sit amet consectetur. Donec duis auctor egestas nisl sapien urna
              bibendum sagittis.
            </p> */}
            <div className="btn-container">
              <button className="btn">POŠALJI</button>
            </div>
          </div>
        </div>
        <div className="footer-container-right">
          <p className="title">Organizatori:</p>
          <div className="column">
            <p className="column-white">Odnosi sa javnošću:</p>
            <p className="column-red">Zoran Ćirić</p>
            <p className="column-white">zoran.ciric@uos.rs</p>
          </div>
          <div className="column">
            <p className="column-white">Zakon o zaštiti podataka o ličnosti:</p>
            <p className="column-red">Branko Damjanović</p>
            <p className="column-white">branko.damjanovic@uos.rs</p>
          </div>
        </div>
      </div>
      <div className="copyright-container">
        <p className="copyright">
          Copyrights 2024. Udruženje osiguravača Srbije. Sva prava zadržana. Izrada sajta{' '}
          <a href="https://normasoft.net/">Normasoft</a>
        </p>
      </div>
    </>
  );
};

export default Footer;
